import React from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../config";
import {useStyletron} from "baseui";
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import {LabelMedium} from "baseui/typography";
import {Button} from 'baseui/button';
import Resizer from "react-image-file-resizer";
import {Delete} from 'baseui/icon/delete'

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            400,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const Container = styled.div`
  flex: 1;
  max-width: 90%;
  min-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #000;
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const client = axios.create({
    baseURL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        "Content-Type": "application/json"
    },
});


function OrderUploads() {
    const {order} = useSelector((state) => state.order);
    const [css] = useStyletron()

    let orderId = order.id;
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const [files, setFiles] = React.useState([]);
    const {getRootProps, getInputProps,acceptedFiles, open, isDragAccept, isFocused, isDragReject} = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop: React.useCallback((acceptedFiles) => {
            acceptedFiles.map((file, index) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    setFiles((prevState) => [
                        ...prevState,
                        { id: index, src: e.target.result },
                    ]);
                };
                reader.readAsDataURL(file);
                return file;
            });
        }, [])
    });



    const thumbs = files.map(file => (
        <img className={css({
            maxWidth: '90%',
            marginTop:'1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderWidth: '2px',
            padding: '0 20px',
            borderRadius: '10px',
            borderColor: 'rgb(255, 160, 32)',
            borderStyle: 'solid',
            backgroundColor: '#fafafa',
            color: 'black',
            fontWeight: 'bold',
            fontSize: '1.4rem',
            outline: 'none',
            transition:' border 0.24s ease-in-out',
        })} src={file.src} alt="Image preview"/>
        // console.log(file)
    ));

    React.useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const uploadFile = () => {
        setLoading(true);
        files.forEach(async (file) => {

            const data = {
                file_str: file.src,
            };
            client.post(`/api/admin/orders/${orderId}/uploads`,data).then((response) => {
                console.log(response)
                setMessage('File uploaded successfully')
                setFiles([])
                setLoading(false)
            }).catch(err => {
                console.log(err)
            })
        })
    }

    return (
        <div className={css({
            position: "relative",
            width: "100%",
            display: "flex",
            flexWrap:"wrap",
            padding: "0.5rem",
            background: "#FFFFFF",
            maxHeight: "400px",
            overflow: "auto",
            justifyContent:"center"
        })}>
            <section className="container">
                {message? <div className={css({
                    minWidth: '103%',
                    maxWidth: '103%',
                    color: '#fff',
                    borderRadius:'0.25rem',
                    padding: '0.5rem 0.75rem',
                    backgroundColor:'#1FDA6B',
                    marginBottom: '1rem'
                })}>
                    {message}
                </div>: null}
                <Container className="dropbox" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <LabelMedium>Drag 'n' drop some files here, or click to select files</LabelMedium>
                </Container>
                <aside className={css({

                })}>
                    {thumbs}
                    <div className={css({
                        marginTop: '1rem'
                    })}>
                        {loading? <Button isLoading>Loading</Button> :<Button onClick={() =>uploadFile()}>Upload</Button>}
                    </div>
                </aside>
            </section>
        </div>
    )
}

export default OrderUploads