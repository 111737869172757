import * as TYPES from "./constants";
import { showErrors } from "../helpers/errors";

const RouteReducer = (
  state = {
    isLoading: false,
    detail: "",
    routes: [],
    route: {},
    close: false,
    sideLoading: false,
  },
  action
) => {
  switch (action.type) {
    case TYPES.LOADING:
      return {
        ...state,
        isLoading: true,
        close: false,
      };
    case TYPES.SIDE_LOADING:
      return {
        ...state,
        sideLoading: true,
      };
    case TYPES.GET_ROUTES:
      return {
        ...state,
        routes: action.payload.routes,
        isLoading: false,
      };
    case TYPES.GET_ROUTE:
      return {
        ...state,
        route: action.payload,
        isLoading: false,
      };
    case TYPES.EDIT_ROUTE:
      return {
        ...state,
        routes: state.routes.filter((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        isLoading: false,
        route: action.payload,
        close: true,
        sideLoading: false,
      };
    case TYPES.CREATE_ROUTE:
      return {
        ...state,
        routes: [...state.routes, action.payload],
        route: action.payload,
        isLoading: false,
        close: true,
      };
    case TYPES.ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        detail: action.payload?.detail,
        isLoading: false,
        sideLoading: false
      };
    case TYPES.DELETE:
      return {
        ...state,
        isLoading: false,
        routes: state.routes.filter((item) => item.id !== action.route_id),
      };
    default:
      return state;
  }
};

export default RouteReducer;
