/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

export function useRouting({ pickup, dropoff }) {
  const [trail, setTrail] = useState({ type: "LineString" });
  const [state, setState] = useState({
    pickup: {
      lat: null,
      lon: null,
    },
    dropoff: {
      lat: null,
      lon: null,
    },
  });

  const TOKEN =
    "pk.eyJ1IjoicXVhdHJpeGdsb2JhbCIsImEiOiJja2ZpNHVhM2gwZXltMnBxdGdqbWtrdmkyIn0.T2QUY4ugqoJ6r9yO_I6KRw";

  function getRoute(lat, lon) {
    axios
      .get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${lat},${lon};${dropoff.lon},${dropoff.lat}?alternatives=true&geometries=geojson&steps=true&access_token=${TOKEN}`
      )
      .then((res) => {
        setTrail((prev) => ({ ...prev, ...res.data.routes[0].geometry }));
      });
  }

  useEffect(() => {
    if (pickup.lat && dropoff.lat) {
      setState((prev) => ({
        ...prev,
        pickup: {
          ...state.pickup,
          ...pickup,
        },
        dropoff: {
          ...state.dropoff,
          ...dropoff,
        },
      }));
    }
  }, [pickup, dropoff]);

  useEffect(() => {
    if (state.pickup.lat && state.dropoff.lat) {
      getRoute(state.pickup.lon, state.pickup.lat);
    }
  }, [state.pickup.lat, state.dropoff.lat]);

  return [trail];
}
