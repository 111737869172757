import * as config from "../config";

const QX_ASSET_MAPPING = {
  logo: "https://res.cloudinary.com/quatrix/image/upload/v1634296259/assets/web/quatrix/white.png",
  label: "Operations | Quatrix Global",
  small_text: "Ponty",
  traccarURL: config.quatrixTraccarUrl,
  email: "info@quatrixglobal.com",
  mobileTraccarURL: config.quatrixMobileTraccarUrl,
  websocketMobileTraccarURL: `wss${config?.quatrixMobileTraccarUrl?.substring(
    5
  )}`,
  websocketTraccarURL: `wss${config?.quatrixTraccarUrl?.substring(5)}`,
  favicon:
    "https://res.cloudinary.com/quatrix/image/upload/v1634280361/assets/web/quatrix/favicon.ico",
  manifest: "/quatrix-manifest.json",
};

export default QX_ASSET_MAPPING;
