import moment from "moment";

function getDateDiff(to, from, message) {
  const now = moment(to);
  const end = moment(from);

  const duration = moment.duration(now.diff(end));
  const minutes = parseInt(duration.asMinutes(), 10);
  const hours = parseInt(duration.asHours(), 10);
  const days = parseInt(duration.asDays(), 10);

  return minutes < 60
    ? {
        text: `${minutes}m`,
        digit: minutes,
        message,
        isMinute: true,
      }
    : hours < 24
    ? {
        text: `${hours}h`,
        digit: hours,
        message,
      }
    : {
        text: `${days}d`,
        digit: days,
        message,
      };
}

function changedAt(order, status) {
  return order.order_status_histories.filter(
    (item) => item.new_status === status
  )[0]?.changed_at;
}

export function orderDurationChecker(order) {
  if (
    order &&
    order.id &&
    order.order_status_histories &&
    order.order_status_histories.length > 0
  ) {
    const status = order.order_status_id;
    if (status === "delivered") {
      const deliveredAt = changedAt(order, status);
      const originDate = changedAt(order, "in_transit_to_pickup");
      return getDateDiff(deliveredAt, originDate, "Order processing time");
    }

    if (status === "created") {
      const createdAt = changedAt(order, "created");
      const currentTime = new Date().toISOString();

      return getDateDiff(
        currentTime,
        createdAt,
        "Duration post-order creation"
      );
    }

    if (status === "at_pickup") {
      const createdAt = changedAt(order, "at_pickup");
      const currentTime = new Date().toISOString();

      return getDateDiff(
        currentTime,
        createdAt,
        "Time elapsed while the Rider is at pickup"
      );
    }
    return {
      text: "--",
      digit: 0,
      message: "No time",
      isMinute: true,
    };
  } else {
    return {
      text: "--",
      digit: 0,
      message: "No time",
      isMinute: true,
    };
  }
}
