export const GET_ROUTE_PLACES = "GET_ROUTE_PLACES";
export const GET_SINGLE_ROUTE_PLACES = "GET_SINGLE_ROUTE_PLACES";
export const DELETE_ROUTE_PLACES = "DELETE_ROUTE_PLACES";
export const EDIT_ROUTE_PLACES = "EDIT_ROUTE_PLACES";
export const LOADING = "ROUTE_PLACES_LOADING";
export const ERROR = "ROUTE_PLACES_ERROR";
export const CREATE_ROUTE_PLACES = "CREATE_ROUTE_PLACES";
export const BULK_SAVING = "BULK_SAVING";
export const BULK_COMPLETE = "BULK_COMPLETE";
export const SYNC = "SYNC";
