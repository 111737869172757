import toast from "react-hot-toast";
import { showErrors } from "../helpers/errors";
import { capitalizer } from "../helpers/string";
import * as TYPES from "./constants";

export default function OrderReducer(
  state: any = {
    isLoading: false,
    sideLoading: false,
    orders: [],
    order: {},
    detail: "",
    updating: false,
    history: [],
  },
  action: {
    type: string,
    payload?: any,
  }
) {
  switch (action.type) {
    case TYPES.GET_ORDERS:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.GET_SINGLE_ORDER:
      return {
        ...state,
        sideLoading: true,
      };
    case TYPES.GET_STATUS:
      return {
        ...state,
        history: action.payload,
      };
    case TYPES.LIST_ORDERS:
      return {
        ...state,
        orders: action.payload?.items,
        isLoading: false,
        page: action?.payload?.page,
        paginate: action?.payload?.paginate,
        total: action?.payload.total,
        pages: Math.round(action.payload.total / action.payload.paginate) + 1,
      };
    case TYPES.LIST_ORDER:
      return {
        ...state,
        order: action.payload,
        sideLoading: false,
      };
    case TYPES.ORDER_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        sideLoading: false,
        detail: action.payload?.detail,
      };
    case TYPES.ORDER_STATUS_UPDATE:
      return {
        ...state,
        isLoading: false,
        order: action.payload,
        updating: false,
        orders: state.orders.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case TYPES.UPDATE:
      return {
        ...state,
        isLoading: true,
        updating: true,
      };
    case TYPES.UPDATE_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        updating: false,
        sideLoading: false,
      };
    case TYPES.SYNC_ORDER:
      if (action.payload.order_status_id !== state.order.order_status_id) {
        toast(`Order update: ${capitalizer(action.payload.order_status_id)}`, {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }

      if (action.payload.invoice_status_id !== state.order.invoice_status_id) {
        toast(
          `Payment update: ${capitalizer(action.payload.invoice_status_id)}`,
          {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          }
        );
      }

      return {
        ...state,
        order: action.payload,
        sideLoading: false,
      };
    case TYPES.DISPUTE:
      return {
        ...state,
        sideLoading: false,
      };
    default:
      return state;
  }
}
