import React from "react";
import { ProgressSteps, Step } from "baseui/progress-steps";
import { useSelector } from "react-redux";
import { capitalizer } from "../helpers/string";
import moment from "moment";
import momentTz from "moment-timezone";

export default function OrderHistory() {
  const { history, order } = useSelector((state) => state.order);

  return (
    <ProgressSteps current={order.order_status_id}>
      {history.map((item, idx) => {
        return (
          <Step
            title={`${capitalizer(item.new_status)} ${moment(
              momentTz.tz(item.changed_at, momentTz.tz.guess())
            ).format("D/MM/YYYY, h:mm a")}`}
          ></Step>
        );
      })}
    </ProgressSteps>
  );
}
