/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import * as React from "react";
import { useStyletron } from "baseui";
import { Link, useHistory } from "react-router-dom";
import { Layer } from "baseui/layer";
import { AppNavBar, setItemActive } from "baseui/app-nav-bar";
import { useSelector } from "react-redux";
import assetMapping from '../../asset-mapping'

type NavProps = {
  activePathName: string,
  public: boolean,
};

const Navbar = (props: NavProps) => {
  const [css] = useStyletron();

  const { user } = useSelector((state) => state.auth);

  const [mainItems, setMainItems] = React.useState([
    {
      label: "Carriers",
      key: "/carriers",
      parent: true,
      children: [
        {
          label: "Operators",
          key: "/carriers",
        },
        {
          label: "Vehicles",
          key: "/carriers/vehicles",
        },
        {
          label: "Drivers",
          key: "/carriers/drivers",
        },
      ],
    },
    // {
    //   label: "Pricing",
    //   key: "/pricing",
    //   parent: true,
    //   children: [
    //     {
    //       label: "Business[B2B]",
    //       key: "/pricing",
    //     },
    //     {
    //       label: "Agent[B2A]",
    //       key: "/pricing/agent",
    //     },
    //     {
    //       label: "Standard[B2C]",
    //       key: "/pricing/standard",
    //     },
    //     // {
    //     //   label: "Price List",
    //     //   key: "/pricing/products",
    //     // },
    //     // {
    //     //   label: "Places & Routes",
    //     //   key: "/pricing/places-and-routes",
    //     // },
    //   ],
    // },
    // {
    //   label: "Routes",
    //   key: "/routes",
    //   parent: true,
    //   children: [
    //     {
    //       label: "Routes",
    //       key: "/routes",
    //     },
    //     {
    //       label: "Places",
    //       key: "/routes/places",
    //     },
    //     {
    //       label: "Products",
    //       key: "/routes/products",
    //     },
    //   ],
    // },
    {
      label: "Operations",
      key: "/operations",
      parent: true,
      children: [],
    },
    {
      label: "Orders",
      key: "/orders",
      parent: true,
      children: [],
    },
    {
      label: "Shippers",
      key: "/shippers",
      parent: true,
      children: [],
    },
    // {
    //   label: "Tracking",
    //   key: "/tracking",
    //   parent: true,
    //   children: [],
    // },
    {
      label: "Reports",
      key: "/reports",
      parent: true,
      children: [
        {
          label: "Speed",
          key: "/reports",
        },
        {
          label: "Night",
          key: "/reports/night",
        },
      ],
    },
    {
      label: "Settings",
      key: "/settings",
      parent: true,
      children: [],
    },
    // // {
    // //   label: "Admin",
    // //   key: "/admin",
    // //   parent: true,
    // //   children: [
    // //     {
    // //       label: "Vehicles",
    // //       key: "/admin/vehicles",
    // //     },
    // //   ],
    // // },
  ]);

  const userItems = [
    {
      label: "Account Settings",
      key: "/profile",
    },
  ];

  const history = useHistory();

  const handleSetActiveItem = (item) => {
    history.push(item.key);
    setMainItems((prev) => {
      return setItemActive(prev, item);
    });
  };

  React.useEffect(() => {
    let newItems = [];
    mainItems.forEach((item) => {
      if (
        item.key === props.activePathName ||
        (props.activePathName.includes(item.key) && item.parent)
      ) {
        item["active"] = true;

        item.children.forEach((child) => {
          if (child.key === props.activePathName) {
            child["active"] = true;
          }
        });

        newItems.push(item);
      } else {
        newItems.push(item);
      }
    });

    setMainItems(newItems);
  }, [props.activePathName]);

  return (
    <React.Fragment>
      <Layer>
        <div
          className={css({
            boxSizing: "border-box",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
          })}
        >
          {props.public ? (
            <AppNavBar
              title={
                <Link to="/">
                  <img
                    src={assetMapping.logo}
                    alt="logo"
                  />
                </Link>
              }
              userItems={props.public ? [] : userItems}
              mainItems={props.public ? [] : mainItems}
              onMainItemSelect={handleSetActiveItem}
              mapItemToNode={(item) => (
                <div
                  className={css({
                    color: item.active ? "#000" : "#9c9c9c",
                  })}
                >
                  <Link
                    className={css({
                      textDecoration: "none",
                      color: "inherit",
                    })}
                    to={item.key}
                  >
                    {item.label}
                  </Link>
                </div>
              )}
              overrides={{
                Root: {
                  style: () => {
                    return { backgroundColor: `#fff`, marginBottom: "16px" };
                  },
                },
                PrimaryMenuContainer: {
                  style: ({ $theme }) => {
                    return {
                      display: "flex",
                      justifyContent: "center",
                    };
                  },
                },
                SecondaryMenuContainer: {
                  style: ({ $theme }) => {
                    return {
                      display: "flex",
                      justifyContent: "center",
                    };
                  },
                },
              }}
            />
          ) : (
            <AppNavBar
              title={
                <Link to="/">
                  <img
                    src={assetMapping.logo}
                    style={{ 
                      width: "100px",
                    }}
                    alt="logo"
                  />
                </Link>
              }
              userItems={props.public ? [] : userItems}
              mainItems={props.public ? [] : mainItems}
              username={user?.name}
              usernameSubtitle={user?.job_title}
              userImgUrl=""
              onMainItemSelect={handleSetActiveItem}
              mapItemToNode={(item) => (
                <div
                  className={css({
                    color: item.active ? "#000" : "#9c9c9c",
                  })}
                >
                  <Link
                    className={css({
                      textDecoration: "none",
                      color: "inherit",
                    })}
                    to={item.key}
                  >
                    {item.label}
                  </Link>
                </div>
              )}
              overrides={{
                Root: {
                  style: () => {
                    return { backgroundColor: `#fff`, marginBottom: "16px" };
                  },
                },
                PrimaryMenuContainer: {
                  style: ({ $theme }) => {
                    return {
                      display: "flex",
                      justifyContent: "center",
                    };
                  },
                },
                SecondaryMenuContainer: {
                  style: ({ $theme }) => {
                    return {
                      display: "flex",
                      justifyContent: "center",
                    };
                  },
                },
              }}
            />
          )}
        </div>
      </Layer>
    </React.Fragment>
  );
};

export default Navbar;
