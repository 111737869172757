export const VH_LOADING = "VH_LOADING";
export const VH_ERROR = "VH_ERROR";
export const FETCH_VEHICLE = "FETCH_VEHICLE";
export const VEHICLE_SEC_LOADING = "VEHICLESEC_LOADING";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const GET_STATUS = "GET_STATUS";
export const GET_VEHICLE_CHILD = "GET_VEHICLE_CHILD";
export const VH_CHILD_LOADING = "VH_CHILD_LOADING";
export const SYNC = "SYNC";
export const EDIT_CHILD = "EDIT_CHILD";
export const EDIT_VEHICLE = "EDIT_VEHICLE";
export const UPDATE_CHILD = "UPDATE_CHILD";
export const FETCH_OWNERS = "FETCH_OWNERS";
export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const UPLOAD_DOC = "UPLOAD_VH_DOC";
export const UPDATE_DOC = "UPDATE_VH_DOC";
export const FETCH_DOCS = "FETCH_VH_DOCS";
export const CLOUD_FETCHING = "CLOUD_FETCHING";
export const SAVING = "SAVING";
export const ASSIGN_DRIVERS = "ASSIGN_DRIVERS";
export const ASSIGN_OWNERS = "ASSIGN_OWNERS";
export const REMOVE_DRIVER = "REMOVE_DRIVER";
export const REMOVE_OWNER = "REMOVE_OWNER";
export const DELETING = "DELETING";
export const GETTING_DEVICES = "GETTING_DEVICES";
export const LIST_DEVICES = "LIST_DEVICES";
export const ERROR = "ERROR";
export const GETTING_GROUPS = "GETTING_GROUPS";
export const LIST_GROUPS = "LIST_GROUPS";
export const GET_POSITIONS = "GET_POSITIONS";
export const LIST_POSITION = "LIST_POSITION";
export const REPORT_SUMMARY = "REPORT_SUMMARY";
export const GET_REPORTS = "GET_REPORTS";
