// @flow

import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./hoc/private-route";
import PublicRoute from "./hoc/public-route";
import Loader from "./qui/loader";
import Prices from "./prices";
import OrderTracking from "./order-tracking";
import SpeedReport from "./speed-report";
import SingleOrder from "./orders/single-order-page";

const ProfilePage = lazy(() => import("./profile"));
const Home = lazy(() => import("./home"));
const SignIn = lazy(() => import("./sign-in/"));
const NotFound = lazy(() => import("./not-found/not-found"));
const Operators = lazy(() => import("./operators"));
const SingleOperator = lazy(() => import("./single-operator"));
const Vehicles = lazy(() => import("./vehicles"));
const Vehicle = lazy(() => import("./vehicle"));
const DriversPage = lazy(() => import("./drivers"));
const Personnels = lazy(() => import("./personnel"));
const Products = lazy(() => import("./products"));
const CarrierSettings = lazy(() => import("./settings"));
const AddCarrier = lazy(() => import("./add-carrier"));
const PlacesPage = lazy(() => import("./places"));
const ShipperRoutes = lazy(() => import("./shipper-routes"));
const ShipperRoutesPlaces = lazy(() => import("./shipper-routes-places"));
const OrdersPage = lazy(() => import("./orders"));
const SingleOrderPage = lazy(() => import("./single-order"));
const TrackingPage = lazy(() => import("./tracking"));
const ShippersPage = lazy(() => import("./shippers"));
const ShipperPage = lazy(() => import("./shippers/single-shipper"));
const UserDetails = lazy(() => import('./users/user-details'))

const Routes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute exact={true} path="/">
          <Suspense fallback={<Loader />}>
            <Home />
          </Suspense>
        </PrivateRoute>
        <PublicRoute exact={true} path="/sign-in">
          <Suspense fallback={<Loader />}>
            <SignIn />
          </Suspense>
        </PublicRoute>
        <PrivateRoute exact={true} path="/carriers">
          <Suspense fallback={<Loader />}>
            <Operators />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/profile">
          <Suspense fallback={<Loader />}>
            <ProfilePage />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/carriers/drivers">
          <Suspense fallback={<Loader />}>
            <DriversPage />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/carriers/vehicles">
          <Suspense fallback={<Loader />}>
            <Vehicles />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/settings">
          <Suspense fallback={<Loader />}>
            <CarrierSettings />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/vehicles">
          <Suspense fallback={<Loader />}>
            <Vehicles />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/add-carrier">
          <Suspense fallback={<Loader />}>
            <AddCarrier />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/routes/products">
          <Suspense fallback={<Loader />}>
            <Products />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/routes/places">
          <Suspense fallback={<Loader />}>
            <PlacesPage />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/pricing">
          <Prices type="business" />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/pricing/standard">
          <Prices type="standard" />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/pricing/agent">
          <Prices type="agent" />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/routes">
          <Suspense fallback={<Loader />}>
            <ShipperRoutes />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/pricing/places-and-routes">
          <Suspense fallback={<Loader />}>
            <ShipperRoutesPlaces />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/operations">
          <Suspense fallback={<Loader />}>
            <Personnels />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/shippers">
          <Suspense fallback={<Loader />}>
            <ShippersPage />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/shippers/:shipper_id">
          <Suspense fallback={<Loader />}>
            <ShipperPage />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/operator/:operator_id">
          <Suspense fallback={<Loader />}>
            <SingleOperator />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/shippers/:shipper_id/users/:user_id">
            <Suspense fallback={<Loader />}>
              <UserDetails />
            </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/vehicle/:vehicle_id">
          <Suspense fallback={<Loader />}>
            <Vehicle />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/orders">
          <Suspense fallback={<Loader />}>
            <OrdersPage />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/tracking">
          <TrackingPage />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/orders/:order_id">
          <Suspense fallback={<Loader />}>
            <SingleOrderPage />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/reports">
          <Suspense fallback={<Loader />}>
            <SpeedReport />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/order/:order_id">
          <Suspense fallback={<Loader />}>
            <SingleOrder />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="/order/:order_id/tracking">
          <Suspense fallback={<Loader />}>
            <OrderTracking />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute exact={true} path="*">
          <NotFound />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
};

export default Routes;
