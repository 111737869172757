// @flow

import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const VehicleReducer = (
  state: any = {
    vehicleLoading: false,
    vehicle: {},
    child: {},
    detail: "",
    vehicleSecondaryLoading: false,
    statuses: [],
    isFetchingChild: true,
    owners: [],
    drivers: [],
    vehicle_uploads: [],
    cloudFetching: false,
    deleting: false,
    saving: false,
    close: false,
    devices: [],
    groups: [],
    groupsLoading: false,
    positions: [],
    positionsLoading: false,
    single: true,
    recall: true,
    deviceId: null,
    reportsLoading: false,
    report: [],
  },
  action: any
) => {
  switch (action.type) {
    case TYPES.FETCH_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
        vehicleLoading: false,
      };
    case TYPES.GET_VEHICLE_CHILD:
      return {
        ...state,
        child: action.payload,
        isFetching: false,
        vehicleLoading: false,
      };
    case TYPES.VH_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        vehicleLoading: false,
        detail: action.payload?.detail,
        sync: false,
      };
    case TYPES.VH_LOADING:
      return {
        ...state,
        vehicleLoading: true,
      };
    case TYPES.VH_CHILD_LOADING:
      return {
        ...state,
        isFetchingChild: true,
      };
    case TYPES.VEHICLE_SEC_LOADING:
      return {
        ...state,
        vehicleSecondaryLoading: true,
      };
    case TYPES.GET_STATUS:
      return {
        ...state,
        statuses: action.payload.statuses,
        vehicleSecondaryLoading: false,
      };
    case TYPES.UPDATE_CHILD:
      return {
        ...state,
        child: { ...state.child, ...action.payload },
        sync: false,
      };
    case TYPES.SYNC:
      return {
        ...state,
        sync: true,
      };
    case TYPES.EDIT_VEHICLE:
      return {
        ...state,
        child: action.payload,
        sync: false,
      };
    case TYPES.EDIT_CHILD:
      return {
        ...state,
        child: action.payload,
        sync: false,
      };
    case TYPES.UPDATE_STATUS:
      return {
        ...state,
        vehicle: { ...state.vehicle, ...action.payload },
        vehicleSecondaryLoading: false,
      };
    case TYPES.FETCH_DRIVERS:
      return {
        ...state,
        vehicleSecondaryLoading: false,
        drivers: action.payload.drivers,
      };
    case TYPES.FETCH_OWNERS:
      return {
        ...state,
        vehicleSecondaryLoading: false,
        owners: action.payload.owners,
      };
    case TYPES.UPLOAD_DOC:
      return {
        ...state,
        vehicleSecondaryLoading: false,
        vehicle_uploads: [...state.vehicle_uploads, action.payload],
        cloudFetching: false,
      };
    case TYPES.UPDATE_DOC:
      return {
        ...state,
        vehicleSecondaryLoading: false,
        vehicle_uploads: [
          ...state.vehicle_uploads.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        ],
        cloudFetching: false,
      };
    case TYPES.FETCH_DOCS:
      return {
        ...state,
        vehicleSecondaryLoading: false,
        vehicle_uploads: action.payload.uploads,
        vehicleLoading: false,
        cloudFetching: false,
      };
    case TYPES.CLOUD_FETCHING:
      return {
        ...state,
        cloudFetching: true,
      };
    case TYPES.ASSIGN_DRIVERS:
      return {
        ...state,
        drivers: state.drivers && [...state.drivers, action.payload],
        saving: false,
        close: true,
      };
    case TYPES.ASSIGN_OWNERS:
      return {
        ...state,
        owners: state.owners && [...state.owners, action.payload],
        saving: false,
        close: true,
      };
    case TYPES.REMOVE_DRIVER:
      return {
        ...state,
        deleting: false,
        drivers: state.drivers.filter((item) => item.id !== action.driver_id),
      };
    case TYPES.REMOVE_OWNER:
      return {
        ...state,
        deleting: false,
        owners: state.owners.filter((item) => item.id !== action.owner_id),
      };
    case TYPES.SAVING:
      return {
        ...state,
        saving: true,
        close: false,
      };
    case TYPES.DELETING:
      return {
        ...state,
        deleting: true,
      };
    case TYPES.GETTING_DEVICES:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.LIST_DEVICES:
      return {
        ...state,
        devices: action.payload,
        isLoading: false,
      };
    case TYPES.ERROR:
      showErrors([], "Bad request, please try again");
      return {
        ...state,
        detail: "Bad request, please try again",
        isLoading: false,
      };
    case TYPES.GETTING_GROUPS:
      return {
        ...state,
        groupsLoading: true,
      };
    case TYPES.LIST_GROUPS:
      return {
        ...state,
        groups: action.payload,
        groupsLoading: false,
      };
    case TYPES.GET_POSITIONS:
      return {
        ...state,
        positionsLoading: true,
      };
    case TYPES.LIST_POSITION:
      return {
        ...state,
        positions: action.payload,
        positionsLoading: false,
        single: action.single,
        recall: action.recall,
        deviceId: action.deviceId || null,
      };
    case TYPES.GET_REPORTS:
      return {
        ...state,
        reportsLoading: true,
      };
    case TYPES.REPORT_SUMMARY:
      return {
        ...state,
        reportsLoading: false,
        report: action.payload,
      };
    default:
      return state;
  }
};

export default VehicleReducer;
