import { createContext, useContext, useEffect, useState } from "react";

export const LVPContext = createContext({
  setValue: () => {},
  getLocalValue: () => {},
  clearLocalValue: () => {},
});

export const LVPProvider = ({ children }) => {
  const [localValue, setLocalValue] = useState({
    "order-active-tab": "0",
    "operator-active-tab": "0",
    "vehicle-active-tab": "0",
    "operator-side-active-tab": "0",
    "vehicle-side-active-tab": "0",
    "shipper-active-tab": "0",
    "driver-side-active-tab": "0",
  });

  function setAllValues() {
    Object.keys(localValue).forEach((key) => {
      if (localStorage.getItem(key) === "0") {
        localStorage.setItem(key, localValue[key]);
      }
    });
  }

  function getCurrentValues() {
    Object.keys(localValue).forEach((key) => {
      const result = localStorage.getItem(key);

      console.log(result)

      if (result) {
        setLocalValue((prev) => ({
          ...prev,
          [key]: result,
        }));
      }
    });
  }

  useEffect(() => {
    setAllValues();

    // window.onstorage = () => {
    getCurrentValues();
  }, []);

  function clearLocalValue(key) {
    localStorage.setItem(key, "0");
    setLocalValue((prev) => ({
      ...prev,
      [key]: "0",
    }));
  }

  function getLocalValue(key) {
    return localValue[key];
  }

  function setValue(key, value) {
    localStorage.setItem(key, value);
    setLocalValue((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  return (
    <LVPContext.Provider
      value={{
        setValue,
        getLocalValue,
        clearLocalValue,
      }}
    >
      {children}
    </LVPContext.Provider>
  );
};

export const useLVP = () => {
  return useContext(LVPContext);
};
