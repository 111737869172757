// @flow

import axios from "../axios";
import { baseURL } from "../config";

export const login = async (identifier, password) => {
  try {
    let response = await axios.post(baseURL + "/api/admin/personnel/login", {
      identifier: identifier,
      password: password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const operatorProfile = async () => {
  try {
    let response = await axios.get(baseURL + "/api/admin/personnel/profile");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const operatorProfileUpdate = async (payload: any) => {
  try {
    let response = await axios.patch(
      baseURL + "/api/admin/personnel/profile",
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
