// @flow

import axios from "../axios";

export const createPrices = async (payload: any) => {
  try {
    let response = await axios.post(`/api/admin/products/variants`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUom = async () => {
  try {
    let response = await axios.get(`/api/uom`)

    return response.data
  } catch (error) {
    throw error;
  }
}

export const updatePrices = async (payload: any, price_id: number) => {
  try {
    let response = await axios.patch(
      `/api/admin/products/variants/${price_id}`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePrices = async (price_id: number) => {
  try {
    let response = await axios.delete(`/api/admin/products/variants/${price_id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPrices = async (price_id?: number) => {
  try {
    let response = await axios.get(
      price_id
        ? `/api/admin/products/variants/${price_id}`
        : `/api/admin/products/variants`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProducts = async (payload) => {
  try {
    let response = await axios.post(`/api/admin/products`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProducts = async (payload: any, product_id: number) => {
  try {
    let response = await axios.patch(
      `/api/admin/products/${product_id}`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProducts = async (product_id?: number) => {
  try {
    let response = await axios.get(
      `/api/admin/products${product_id ? `/${product_id}` : "/"}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVehicleVariants = async (variant: string) => {
  try {
    let response;

    switch (variant) {
      case "types":
        response = await axios.get(`/api/admin/vehicles/types`);
        break;
      case "bodies":
        response = await axios.get(`/api/admin/vehicles/bodies`);
        break;
      case "sizes":
        response = await axios.get(`/api/admin/vehicles/sizes`);
        break;
      case "makes":
        response = await axios.get(`/api/admin/vehicles/makes`);
        break;
      case "models":
        response = await axios.get(`/api/admin/vehicles/makes/models`);
        break;
      default:
        response = await axios.get(`/api/admin/vehicles/types`);
        break;
    }

    let payload;

    if (response.data.bodys) {
      payload = {
        bodies: response.data.bodys,
      };
    } else {
      payload = {
        [variant]: response.data[variant],
      };
    }

    return payload;
  } catch (error) {
    throw error;
  }
};

export const deleteProduct = async (product_id) => {
  try {
    let response = await axios.delete(`/api/admin/products/${product_id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGlobalVariants = async (variant) => {
  try {
    let response;

    switch (variant) {
      case "currencies":
        response = await axios.get(`/api/currencies`);
        break;
      case "countries":
      default:
        response = await axios.get(`/api/countries`);
        break;
    }

    let payload = {
      [variant]: response.data[variant],
    };

    return payload;
  } catch (error) {
    throw error;
  }
};
