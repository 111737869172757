// @flow

import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const LoginReducer = (
  state: any = {
    isLoading: false,
    message: "",
    token: "",
    detail: "",
    isUpdating: false,
    success: false,
    is_profile: false
  },
  action: any
) => {
  switch (action.type) {
    case TYPES.SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    case TYPES.SIGN_IN_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
        detail: action.payload?.detail,
        success: false,
      };
    case TYPES.SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        success: true,
      };
    case TYPES.CLEAR_ERRORS:
      return {
        ...state,
        isLoading: false,
        detail: "",
        message: "",
      };
    case TYPES.SET_CURRENT_USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.SET_CURRRENT_OPERATOR:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    case TYPES.UPDATE_LOADING:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.UPDATE_DETAILS:
      return {
        ...state,
        isUpdating: false,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;
