// @flow

import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const OperatorReducer = (
  state: any = {
    isLoading: false,
    message: "",
    operator: {},
    detail: "",
    secondaryLoading: false,
    statuses: [],
    child: {},
    isFetchingChild: true,
    managees: [],
    cloudFetching: false,
    uploads: [],
    child_uploads: [],
    close: false,
    vehicles: [],
    owns: [],
    drives: [],
    saving: false,
    deleting: true,
    carrierSaving: false,
    vehicle: {},
    assigning: false,
  },
  action: any
) => {
  switch (action.type) {
    case TYPES.ADD_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
        vehicles: [action.payload, ...state.vehicles],
        saving: false,
        isLoading: false,
        close: true,
      };
    case TYPES.FETCH_CARRIER:
      return {
        ...state,
        operator: action.payload,
        isLoading: false,
        managees: state.managees.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case TYPES.GET_CARRIER_CHILD:
      return {
        ...state,
        child: action.payload,
        managees: state.managees.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        isFetchingChild: false,
        isLoading: false,
      };
    case TYPES.CARRIER_ERROR:
    case TYPES.OPS_ERROR:
      action.payload?.detail !== "Operator not found." &&
        showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        detail: action.payload?.detail,
        sync: false,
        saving: false,
      };
    case TYPES.OPS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.OPS_CHILD_LOADING:
      return {
        ...state,
        isFetchingChild: true,
      };
    case TYPES.SEC_LOADING:
      return {
        ...state,
        secondaryLoading: true,
        close: false,
      };
    case TYPES.GET_STATUS:
      return {
        ...state,
        statuses: action.payload.statuses,
        secondaryLoading: false,
      };
    case TYPES.UPDATE_CHILD:
      return {
        ...state,
        child: { ...state.child, ...action.payload },
        sync: false,
      };
    case TYPES.SYNC:
      return {
        ...state,
        sync: true,
        close: false,
      };
    case TYPES.EDIT_OPERATOR:
      return {
        ...state,
        operator: action.payload,
        sync: false,
      };
    case TYPES.EDIT_CHILD:
      return {
        ...state,
        child: action.payload,
        sync: false,
        managees: [
          ...state.managees.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        ],
      };
    case TYPES.UPDATE_STATUS:
      return {
        ...state,
        operator: { ...state.operator, ...action.payload },
        secondaryLoading: false,
      };
    case TYPES.FETCH_MANAGEES:
      return {
        ...state,
        managees: action.payload.carriers.items,
        secondaryLoading: false,
        vehicleLoading: false,
        sort: action.sort,
        dir: action.dir,
        per_page: action.payload.carriers.per_page,
        total:
          Math.round(
            action.payload.carriers.total / action.payload.carriers.paginate
          ) + 1,
        page: action.payload.carriers.page,
        reset: false,
      };
    case TYPES.CLOUD_FETCH:
      return {
        ...state,
        cloudFetching: true,
        secondaryLoading: false,
        close: false,
      };
    case TYPES.UPLOAD_DOCS:
      return {
        ...state,
        uploads: [...state.uploads, action.payload],
        cloudFetching: false,
        close: true,
      };
    case TYPES.UPLOAD_CHILD_DOCS:
      return {
        ...state,
        child_uploads: [...state.child_uploads, action.payload],
        cloudFetching: false,
        close: true,
      };
    case TYPES.UPDATE_CHILD_DOCS:
      return {
        ...state,
        child_uploads: [
          ...state.child_uploads.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        ],
        cloudFetching: false,
        close: true,
      };
    case TYPES.UPDATE_DOCS:
      return {
        ...state,
        uploads: [
          ...state.uploads.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        ],
        cloudFetching: false,
        close: true,
      };
    case TYPES.DELETE_CHILD_DOCS:
      return {
        ...state,
        child_uploads: [
          ...state.child_uploads.filter((item) => item.id !== action.upload_id),
        ],
        cloudFetching: false,
      };
    case TYPES.DELETE_DOCS:
      return {
        ...state,
        uploads: [
          ...state.uploads.filter((item) => item.id !== action.upload_id),
        ],
        cloudFetching: false,
      };
    case TYPES.FETCH_CHILD_DOCS:
      return {
        ...state,
        child_uploads: action.payload.uploads,
        secondaryLoading: false,
        isFetchingChild: false,
      };
    case TYPES.FETCH_DOCS:
      return {
        ...state,
        uploads: action.payload.uploads,
        secondaryLoading: false,
      };
    case TYPES.FETCH_VEHICLES_UNDER_OPERATOR:
      return {
        ...state,
        vehicles: action.payload.vehicles.items,
        secondaryLoading: false,
      };
    case TYPES.ASSIGN_DRIVERS:
      return {
        ...state,
        drives: [...state.drives, action.payload],
        saving: false,
        close: true,
        vehicles: state.vehicles.filter(
          (item) => item.id !== action.payload.vehicle_id
        ),
        isLoading: false,
      };
    case TYPES.ASSIGN_OWNERS:
      return {
        ...state,
        owns: [...state.owns, action.payload],
        saving: false,
        close: true,
      };
    case TYPES.REMOVE_DRIVER:
      return {
        ...state,
        deleting: false,
        drives: state.drives.filter((item) => item.id !== action.driver_id),
      };
    case TYPES.REMOVE_OWNER:
      return {
        ...state,
        deleting: false,
        owns: state.owns.filter((item) => item.id !== action.owner_id),
      };
    case TYPES.SAVING:
      return {
        ...state,
        saving: true,
        close: false,
      };
    case TYPES.DELETING:
      return {
        ...state,
        deleting: true,
      };
    case TYPES.FETCH_DRIVERS:
      return {
        ...state,
        secondaryLoading: false,
        drives: action.payload.vehicles.items,
      };
    case TYPES.FETCH_OWNERS:
      return {
        ...state,
        secondaryLoading: false,
        owns: action.payload.vehicles.items,
      };
    case TYPES.CARRIER_SUCCESS:
      return {
        ...state,
        managees: [action.payload, ...state.managees],
        carrierSaving: false,
        close: true,
      };
    case TYPES.ADDING_CARRIER:
      return {
        ...state,
        carrierSaving: true,
        close: false,
      };
    case "VEHICLE_STATUS_UPDATE":
      return {
        ...state,
        secondaryLoading: false,
      };
    case TYPES.ASSIGNMENT:
      return {
        ...state,
        assigning: true,
      };
    case TYPES.DRIVER_ADDED:
      return {
        ...state,
        drives: [...state.drivers, action.payload],
        assigning: false,
      };
    case TYPES.OWNER_ADDED:
      return {
        ...state,
        owns: [...state.owns, action.payload],
        assigning: false,
      };
    case TYPES.ASSIGNMENT_ERROR:
      action.detail &&
        showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        detail: action.payload?.detail,
        assigning: false,
      };
    case TYPES.DRIVING:
      return {
        ...state,
        drives: action.payload.vehicles,
        assigning: false,
      };
    case TYPES.OWNING:
      return {
        ...state,
        owns: action.payload.vehicles,
        assigning: false,
      };
    case TYPES.OREMOVE_ASSIGNMENT:
      return {
        ...state,
        owns: state.owns.filter((item) => item.id !== action.owner_id),
        deleting: false,
      };
    case TYPES.DREMOVE_ASSIGNMENT:
      return {
        ...state,
        drives: state.drives.filter((item) => item.id !== action.driver_id),
        deleting: false,
      };
    default:
      return state;
  }
};

export default OperatorReducer;
