export const OPS_LOADING = "OPS_LOADING";
export const OPS_ERROR = "OPS_ERROR";
export const FETCH_CARRIER = "FETCH_CARRIER";
export const SEC_LOADING = "SEC_LOADING";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const GET_STATUS = "GET_STATUS";
export const GET_CARRIER_CHILD = "GET_CARRIER_CHILD";
export const OPS_CHILD_LOADING = "CHILD_LOADING";
export const UPDATE_CHILD = "UPDATE_CHILD";
export const SYNC = "SYNC";
export const EDIT_CHILD = "EDIT_CHILD";
export const EDIT_OPERATOR = "EDIT_OPERATOR";
export const FETCH_MANAGEES = "FETCH_MANAGEES";
export const FETCH_DOCS = "FETCH_DOCS";
export const FETCH_CHILD_DOCS = "FETCHING_CHILD_DOCS";
export const CLOUD_FETCH = "CLOUD_FETCH";
export const UPLOAD_DOCS = "UPLOAD_DOCS";
export const UPLOAD_CHILD_DOCS = "UPLOAD_CHILD_DOCS";
export const UPDATE_CHILD_DOCS = "UPDATE_CHILD_DOCS";
export const UPDATE_DOCS = "UPDATE_DOCS";
export const DELETE_DOCS = "DELETE_DOCS";
export const DELETE_CHILD_DOCS = "DELETE_CHILD_DOCS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const FETCH_VEHICLES_UNDER_OPERATOR = "FETCH_VEHICLES_UNDER_OPERATOR";
export const CLOUD_FETCHING = "CLOUD_FETCHING";
export const SAVING = "OPS_SAVING";
export const ASSIGN_DRIVERS = "OPS_ASSIGN_DRIVERS";
export const ASSIGN_OWNERS = "OPS_ASSIGN_OWNERS";
export const REMOVE_DRIVER = "OPS_REMOVE_DRIVER";
export const REMOVE_OWNER = "OPS_REMOVE_OWNER";
export const DELETING = "OPS_DELETING";
export const FETCH_OWNERS = "OPS_FETCH_OWNERS";
export const FETCH_DRIVERS = "OPS_FETCH_DRIVERS";
export const ADDING_CARRIER = "ADDING_CARRIER";
export const CARRIER_SUCCESS = "CARRIER_SUCCESS";
export const CARRIER_ERROR = "CARRIER_ERROR";
export const VEHICLE_STATUS_UPDATE = "VEHICLE_STATUS_UPDATE";
export const VEHICLE_STATUS_CHILD_UPDATE = "VEHICLE_STATUS_CHILD_UPDATE";
export const GET_POTENTIAL_OWNERS = "GET_POTENTIAL_OWNERS";
export const ADD_VEHICLE = "ADD_VEHICLE";
export const DRIVER_ADDED = "DRIVER_ADDED";
export const DRIVING = "DRIVING";
export const OWNING = "OWNING";
export const OWNER_ADDED = "OWNER_ADDED";
export const ASSIGNMENT = "ASSIGNMENT";
export const OREMOVE_ASSIGNMENT = "OREMOVE_ASSIGNMENT";
export const DREMOVE_ASSIGNMENT = "DREMOVE_ASSIGNMENT";
export const ASSIGNMENT_ERROR = "ASSIGNMENT_ERROR";
