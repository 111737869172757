// @flow

import React from "react";
// import { useLocation } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import { fetchToken } from "../../helpers/localstorage";

const PublicRoute = ({ children, exact, path }) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (fetchToken() ? <Redirect to="/" /> : children)}
    />
  );
};

export default PublicRoute;
