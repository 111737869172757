// @flow

import { combineReducers } from "redux";
import LoginReducer from "./sign-in/reducer";
import OperatorsReducer from "./operators/reducer";
import OperatorReducer from "./single-operator/reducer";
import VehiclesReducer from "./vehicles/reducer";
import VehicleReducer from "./vehicle/reducer";
import PersonnelReducer from "./personnel/reducer";
import ProductReducer from "./products/reducers";
import SettingsReducer from "./settings/reducer";
import AddCarrierReducer from "./add-carrier/reducers";
import PlaceReducer from "./places/reducer";
import RoutesReducer from "./shipper-routes/reducer";
import RoutesPlacesReducer from "./shipper-routes-places/reducer";
import OrderReducer from "./orders/reducer";
import TrackingReducer from "./tracking/reducer";
import OrderTracking from "./order-tracking/reducer";
import ShipperReducer from "./shippers/reducer";

const rootReducer = combineReducers({
  auth: LoginReducer,
  operators: OperatorsReducer,
  operator: OperatorReducer,
  vehicles: VehiclesReducer,
  vehicle: VehicleReducer,
  personnel: PersonnelReducer,
  product: ProductReducer,
  settings: SettingsReducer,
  add_carrier: AddCarrierReducer,
  places: PlaceReducer,
  routes: RoutesReducer,
  routes_places: RoutesPlacesReducer,
  order: OrderReducer,
  tracking: TrackingReducer,
  order_tracking: OrderTracking,
  shippers: ShipperReducer,
});

export default rootReducer;
