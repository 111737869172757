// @flow

import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const PersonnelReducer = (
  state: any = {
    isLoading: false,
    sideLoading: false,
    saving: false,
    all: [],
    single: {},
  },
  action: {
    type: string,
    payload?: any,
    personnel_id?: number,
  }
) => {
  switch (action.type) {
    case TYPES.FETCH_PERSONNEL:
      return {
        ...state,
        isLoading: false,
        all: action.payload.personnel,
      };
    case TYPES.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.SAVING:
      return {
        ...state,
        saving: true,
        close: false,
      };
    case TYPES.SIDE_FETCH:
      return {
        ...state,
        sideLoading: true,
      };
    case TYPES.FETCH_SINGLE_PERSONNEL:
      return {
        ...state,
        single: action.payload,
        sideLoading: false,
      };
    case TYPES.UPDATE_PERSONNEL:
      return {
        ...state,
        single: action.payload,
        all: [
          ...state.all.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        ],
        saving: false,
      };
    case TYPES.DELETE_PERSONNEL:
      return {
        ...state,
        single: {},
        all: state.all.filter((item) => item.id !== action.personnel_id),
        saving: false,
        close: true,
      };
    case TYPES.ERRORS:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        saving: false,
        details: action.payload?.detail,
        isLoading: false,
        sideLoading: false,
      };
    case TYPES.CREATE_PERSONNEL:
      return {
        ...state,
        saving: false,
        all: [action.payload, ...state.all],
        single: {},
        isLoading: false,
        sideLoading: false,
        close: true,
      };
    default:
      return state;
  }
};

export default PersonnelReducer;
