// @flow

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRRENT_OPERATOR = "SET_CURRENT_OPERATOR";
export const SET_CURRENT_USER_ERROR = "SET_CURRENT_USER_ERROR";
export const SET_CURRENT_USER_LOADING = "SET_CURRENT_USER_LOADING";
export const UPDATE_DETAILS = "UPDATE_DETAILS";
export const UPDATE_LOADING = "UPDATE_LOADING";
