/* eslint-disable */
// @flow

import axios from "axios";
import assetMapping from "../asset-mapping";

const bikeTraccarClient = axios.create({
  baseURL: `${assetMapping.mobileTraccarURL}/api`,
});

delete bikeTraccarClient.defaults.headers.common["Authorization"];

const traccarClient = axios.create({
  baseURL: `${assetMapping.traccarURL}/api`,
});

delete traccarClient.defaults.headers.common["Authorization"];

export async function getDevices(isBike: boolean) {
  try {
    const response = isBike
      ? await bikeTraccarClient.get(`/devices`)
      : await traccarClient.get(`/devices`);

    return response.data;
  } catch (err) {
    throw err?.response?.data;
  }
}

export async function getGroups(isBike: boolean) {
  try {
    const response = isBike
      ? await bikeTraccarClient.get(`/groups?all=true`)
      : await traccarClient.get(`/groups?all=true`);

    return response.data;
  } catch (err) {
    throw err?.response?.data;
  }
}

export async function getPositionBasedByDevice(
  isBike: boolean,
  deviceId: number,
  from: string,
  to: string
) {
  try {
    const response = isBike
      ? await bikeTraccarClient.get(
          `/positions?deviceId=${deviceId}&from=${from}&to=${to}`
        )
      : await traccarClient.get(
          `/positions?deviceId=${deviceId}&from=${from}&to=${to}`
        );
    return response.data;
  } catch (err) {
    throw err?.response?.data;
  }
}

export async function getAllPositions(isBike: boolean) {
  try {
    const response = isBike
      ? await bikeTraccarClient.get(`/positions?all=true`)
      : await traccarClient.get(`/positions?all=true`);
    return response.data;
  } catch (err) {
    throw err?.response?.data;
  }
}

export async function getReportSummary(
  isBike: boolean,
  deviceId: number,
  from: string,
  to: string
) {
  try {
    const response = isBike
      ? await bikeTraccarClient.get(
          `/reports/summary?deviceId=${deviceId}&from=${from}&to=${to}`
        )
      : await traccarClient.get(
          `/reports/summary?deviceId=${deviceId}&from=${from}&to=${to}`
        );
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
}

export async function realtimeUpdates(isBike: boolean) {
  try {
  } catch (error) {}
}
