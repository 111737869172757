/* eslint-disable */
// @flow

import React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import { fetchToken, setHistoryRoute } from "../../helpers/localstorage";
import Loader from "../../qui/loader";
import { getOperatorProfile } from "../../sign-in/actions";

const PrivateRoute = ({ exact, path, children }) => {
  const { isLoading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getOperatorProfile());
  }, []);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (!fetchToken()) {
          setHistoryRoute();
          return <Redirect to="/sign-in" />;
        } else {
          if (isLoading) {
            return <Loader />;
          } else {
            return children;
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
