/* eslint-disable */

import * as React from "react";
import { StatefulTooltip } from "baseui/tooltip";
import Alert from "baseui/icon/alert";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";

export default function Tooltip(props) {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        display: "flex",
        placeItems: "center",
      })}
    >
      <div>{props.children}</div>
      <StatefulTooltip
        content={() => (
          <Block padding={"8"}>{props.description}</Block>
        )}
      >
        <div className={css({ marginLeft: "4px", width: "24px" })}>
          <Alert size="24px" />
        </div>
      </StatefulTooltip>
    </div>
  );
}
