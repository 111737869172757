/* eslint-disable no-restricted-globals */
import React from "react";
import { useState } from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { Button } from "baseui/button";
import { useDispatch } from "react-redux";
import { paymentDispute } from "./actions";

export default function PaymentDispute({ order }) {
  const [state, setState] = useState({
    gateway: "mpesa",
    reference: "",
    customer_name: "",
    phone_number: "",
    curreny_code: "KES",
    kind_id: "sale",
    status: "success",
    amount: null,
    order_id: order.id,
  });

  const dispatch = useDispatch();

  function onSubmit() {
    if (
      state.customer_name &&
      state.phone_number &&
      state.reference &&
      state.amount
    ) {
      const option = confirm(`Are you sure you want to submit`);
      if (option) {
        dispatch(
          paymentDispute({
            ...state,
          })
        );
      }
    }
  }

  function handleChange(e) {
    const NAME = e.target.name;
    const VALUE = e.target.value;

    setState((prev) => ({
      ...prev,
      [NAME]: NAME === "reference" ? VALUE.toUpperCase() : VALUE,
    }));
  }

  return (
    <section>
      <FormControl label="Transportation Fee">
        <Input
          value={state.amount}
          onChange={handleChange}
          name="amount"
          type="number"
          placeholder="e.g. 1500"
        />
      </FormControl>
      <FormControl label="Reference">
        <Input
          value={state.reference}
          onChange={handleChange}
          name="reference"
          placeholder="e.g. OXEY474839DJ"
        />
      </FormControl>
      <FormControl label="Customer Name">
        <Input
          value={state.customer_name}
          name="customer_name"
          onChange={handleChange}
          placeholder="e.g. John Doe"
        />
      </FormControl>
      <FormControl label="Phone Number">
        <Input
          name="phone_number"
          value={state.phone_number}
          placeholder="e.g. +254711111111"
          onChange={handleChange}
        />
      </FormControl>
      <FormControl label="Payment Gateway">
        <RadioGroup
          value={state.gateway}
          align={ALIGN.vertical}
          onChange={(e) =>
            setState((prev) => ({ ...prev, gateway: e.target.value }))
          }
        >
          <Radio value="mpesa">Mpesa</Radio>
          <Radio value="card">Card</Radio>
        </RadioGroup>
      </FormControl>
      <Button onClick={onSubmit}>Submit</Button>
    </section>
  );
}
