export const FETCH_SHIPPERS = "FETCH_SHIPPERS";
export const FETCHING = "FETCHING";
export const UPDATING = "UPDATING";
export const SIDE_LOADING = "SIDE_LOADING";
export const FETCH_SINGLE_SHIPPER = "FETCH_SINGLE_SHIPPER";
export const FETCH_ORDERS_BY_SHIPPER = "FETCH_ORDERS_BY_SHIPPER";
export const ERRORS = "SHIPPERS_ERRORS";
export const UPDATE_SHIPPER = "UPDATE_SHIPPER";
export const DELETE_SHIPPER = "DELETE_SHIPPER";
export const CREATE_SHIPPER = "CREATE_SHIPPER";
export const CREATING = "CREATING";
export const DELETING = "DELETING";