export const FETCH_PRICES = "FETCH_PRICES";
export const LOADING = "FETCHING_PRODUCT_PRICES";
export const ERROR = "PR_ERROR";
export const UPDATE_PRICES = "UPDATE_PRICES";
export const DELETE_PRICES = "DELETE_PRICES";
export const FETCH_PRICE = "FETCH_PRICE";
export const ADD_PRICE = "ADD_PRICE";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const EDIT_PRODUCTS = "EDIT_PRODUCTS";
export const SAVING = "PR-SAVING";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const SIDE_LOADING = "SIDE_LOADING";
export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const FETCH_GLOBAL_VARIANTS = "GLOBAL_VARIANTS";
export const GET_UOMS = "GET_UOMS";