// @flow

import {
  setHistoryRoute,
  setToken,
  removeToken,
} from "../helpers/localstorage";
import * as TYPES from "./constants";
import { login, operatorProfile, operatorProfileUpdate } from "./service";

export const loginUser = (data: any) => async (dispatch: Function) => {
  dispatch({
    type: TYPES.SIGN_IN_REQUEST,
  });

  login(data?.identifier, data?.password)
    .then((res) => {
      setToken(res.access_token);
      dispatch({
        type: TYPES.SIGN_IN_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.SIGN_IN_ERROR,
        payload: err.response.data,
      });
    });
};

export const getOperatorProfile = () => async (dispatch: Function) => {
  dispatch({
    type: TYPES.SET_CURRENT_USER_LOADING,
  });

  operatorProfile()
    .then((res) => {
      dispatch({
        type: TYPES.SET_CURRRENT_OPERATOR,
        payload: res,
      });
    })
    .catch((err) => {
      setHistoryRoute();
      dispatch({
        type: TYPES.SIGN_IN_ERROR,
        payload: err.response.data,
      });
      removeToken();
      setTimeout(() => {
        window.location.href = `/sign-in`;
      }, 1000);
    });
};

export const editOperatorProfile = (payload: any) => async (
  dispatch: Function
) => {
  dispatch({
    type: TYPES.UPDATE_LOADING,
  });

  operatorProfileUpdate(payload)
    .then((res) => {
      dispatch({
        type: TYPES.UPDATE_DETAILS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.SIGN_IN_ERROR,
        payload: err.response.data,
      });
    });
};
