export const WEIGHT = [
  { label: "kg", id: "kg" },
  { label: "t", id: "t" },
];

export const DISTANCE = [
  { label: "km", id: "km" },
  { label: "m", id: "m" },
];

export const STATUSES = [
  {
    id: "onboarding",
    label: "Onboarding",
    priority: 1,
    is_default: true,
  },
  {
    id: "processing",
    label: "processing",
    priority: 2,
    is_default: false,
  },
  {
    id: "active",
    label: "Active",
    priority: 3,
    is_default: false,
  },
  {
    id: "available",
    label: "Available",
    priority: 4,
    is_default: false,
  },
  {
    id: "engaged",
    label: "Engaged",
    priority: 5,
    is_default: false,
  },
  {
    id: "dormant",
    label: "Dormant",
    priority: 6,
    is_default: false,
  },
  {
    id: "withdrawn",
    label: "Withdrawn",
    priority: 7,
    is_default: false,
  },
  {
    id: "suspended",
    label: "Suspended",
    priority: 8,
    is_default: false,
  },
  {
    id: "blacklisted",
    label: "Blacklisted",
    priority: 9,
    is_default: false,
  },
  {
    id: "archive",
    label: "Archive",
    priority: 10,
    is_default: false,
  },
];

export const VEHICLE_TYPE_CATEGORY = [
  {
    id: "small",
    label: "Small",
    priority: 1,
    is_default: false,
  },
  {
    id: "medium",
    label: "Medium",
    priority: 2,
    is_default: false,
  },
  {
    id: "large",
    label: "Large",
    priority: 3,
    is_default: false,
  },
];

export const ORDER_STATUS = [
  {
    id: "created",
    label: "Created",
    priority: 1,
    is_default: false,
  },
  {
    id: "finalizing_detail",
    label: "Finalizing Detail",
    priority: 2,
    is_default: false,
  },
  {
    id: "matching_carriers",
    label: "Matching Carriers",
    priority: 3,
    is_default: false,
  },
  {
    id: "carrier_confirmed",
    label: "Carrier Confirmed",
    priority: 4,
    is_default: false,
  },
  {
    id: "driver_assigned",
    label: "Driver assigned",
    priority: 5,
    is_default: false,
  },
  {
    id: "in_transit_to_pickup",
    label: "In Transit to Pickup",
    priority: 6,
    is_default: false,
  },
  {
    id: "at_origin",
    label: "At Origin",
    priority: 7,
    is_default: false,
  },
  {
    id: "in_transit",
    label: "In Transit",
    priority: 8,
    is_default: false,
  },
  {
    id: "at_destination",
    label: "At Destination",
    priority: 9,
    is_default: false,
  },
  {
    id: "delivered",
    label: "Delivered",
    priority: 10,
    is_default: false,
  },
  {
    id: "cancelled",
    label: "Cancelled",
    priority: 11,
    is_default: false,
  },
];

export const INVOICE_STATUS = [
  {
    id: "pending",
    name: "Pending",
    priority: 2,
    is_default: false,
  },
  {
    id: "authorized",
    name: "Authorized",
    priority: 2,
    is_default: false,
  },
  {
    id: "partially_paid",
    name: "Partially Paid",
    priority: 2,
    is_default: false,
  },
  {
    id: "paid",
    name: "Paid",
    priority: 3,
    is_default: false,
  },
  {
    id: "partially_refunded",
    name: "Partially Refunded",
    priority: 2,
    is_default: false,
  },
  {
    id: "refunded",
    name: "Refunded",
    priority: 2,
    is_default: false,
  },
  {
    id: "voided",
    name: "Voided",
    priority: 2,
    is_default: false,
  },
];
