import React from "react";
import { ReportProvider } from "../context/report";
import withNav from "../hoc/with-nav";
import SpeedReport from "./speed-report";

function SpeedReportPage() {
  return (
    <ReportProvider>
      <SpeedReport />
    </ReportProvider>
  );
}

export default withNav(SpeedReportPage);
