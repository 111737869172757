// @flow

import React from "react";
import { useLocation } from "react-router-dom";
import { useStyletron } from "baseui";
import Navbar from "../../qui/navbar";

const withNav = (Component: any, loggedOut: boolean, tracking: boolean) => {
  return (props) => {
    const router = useLocation();
    const [css] = useStyletron();

    return (
      <>
        <Navbar activePathName={router.pathname} public={loggedOut} />
        <section
          className={css({
            maxWidth: "1280px",
            margin: tracking ? `90px 0px 0px 0px` : `90px auto 0px auto`,
            width: "100%",
          })}
        >
          <Component {...props} />
        </section>
      </>
    );
  };
};

export default withNav;
