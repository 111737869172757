import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const PlacesReducer = (
  state = {
    isLoading: false,
    places: [],
    place: {},
    close: false
  },
  action
) => {
  switch (action.type) {
    case TYPES.LOADING:
      return {
        ...state,
        isLoading: true,
        close: false
      };
    case TYPES.CREATE_PLACE:
      return {
        ...state,
        isLoading: false,
        place: action.payload,
        places: [...state.places, action.payload],
        close: true
      };
    case TYPES.GET_PLACES:
      return {
        ...state,
        places: action.payload.places,
        isLoading: false,
      };
    case TYPES.DELETE_PLACE:
      return {
        ...state,
        places: state.places.filter((item) => item.id !== action.place_id),
        isLoading: false,
      };
    case TYPES.ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        detail: action.payload?.detail,
        close: false
      };
    case TYPES.GET_SINGLE_PLACE:
      return {
        ...state,
        place: action.payload,
        isLoading: false,
      };
    case TYPES.EDIT_PLACES:
      return {
        ...state,
        place: action.payload,
        places: state.places.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        isLoading: false,
        close: true
      };
    default:
      return state;
  }
};

export default PlacesReducer;
