import toast from "react-hot-toast";

export const showErrors = (
  errors: {
    message: string,
    field: string,
  }[],
  detail?: string
) => {
  if (
    detail &&
    (detail !== "Operator not found." &&
      detail !== "The  access token provided is invalid." &&
      detail !== "No authorization header was found." &&
      detail !== "Operator not found.")
  ) {
    if (errors && errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        if (
          errors[i] !== "The  access token provided is invalid." ||
          errors[i] !== "No authorization header was found."
        ) {
          toast.error(errors[i].message);
        }
      }
    } else {
      if (
        detail !== "The  access token provided is invalid." ||
        detail !== "No authorization header was found." ||
        detail !== "Operator not found."
      ) {
        toast.error(detail);
      } else {
      }
    }
  }
};
