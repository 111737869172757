import { useStyletron } from "baseui";
import React from "react";
import moment from "moment";
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography";

export default function Transactions({ transactions }) {
  const [css] = useStyletron();

  return (
    <section>
      {transactions.map((item) => {
        return (
          <section
            className={css({
              display: "flex",
              justifyContent: "space-between",
              borderTop: "1px solid rgb(220, 218, 218)",
              borderBottom: "1px solid rgb(220, 218, 218)",
              padding: "8px 4px",
              flexWrap: "wrap",
              placeItems: "center",
            })}
          >
            <section
              className={css({
                display: "flex",
                justifyContent: "space-between",
                placeItems: "center",
              })}
            >
              <ParagraphSmall>Reference: </ParagraphSmall>
              <ParagraphXSmall>{item.reference}</ParagraphXSmall>
            </section>
            <section
              className={css({
                display: "flex",
                justifyContent: "space-between",
                placeItems: "center",
              })}
            >
              <ParagraphSmall>Amount: </ParagraphSmall>
              <ParagraphXSmall>KES.{item.amount}</ParagraphXSmall>
            </section>
            <section
              className={css({
                display: "flex",
                justifyContent: "space-between",
                placeItems: "center",
              })}
            >
              <ParagraphSmall>Payment Gateway: </ParagraphSmall>
              <ParagraphXSmall>{item.gateway}</ParagraphXSmall>
            </section>
            <section
              className={css({
                display: "flex",
                justifyContent: "space-between",
                placeItems: "center",
              })}
            >
              <ParagraphSmall>Created: </ParagraphSmall>
              <ParagraphXSmall>
                {moment(item.updated_at).format("LL")}
              </ParagraphXSmall>
            </section>
          </section>
        );
      })}
    </section>
  );
}
