// @flow

export const baseURL =
  process.env.REACT_APP_BACKEND_URL || "https://dev.core.quatrixglobal.com";
export const odooEnv = process.env.REACT_APP_ODOO_ENV;
export const odooTestEnv = process.env.REACT_APP_ODOO_TEST_DEV;
export const notifyEnv = process.env.REACT_APP_NOTIFICATIONS_ENV || "dev";
export const quatrixMobileTraccar = process.env.REACT_APP_MOBILE_TRACCAR_URL;
export const quatrixMobileTraccarUrl = process.env.REACT_APP_MOBILE_TRACCAR_URL;
export const quatrixTraccarUrl = process.env.REACT_APP_TRACCAR_URL;
export const traccarToken = process.env.REACT_APP_TRACCAR_TOKEN;
export const pontyMobileTraccarUrl = process.env.REACT_APP_MOBILE_TRACCAR_URL;
export const pontyTraccarUrl = process.env.REACT_APP_TRACCAR_URL;
