import axios from "axios";

export function setToken(token) {
  if (token) {
    localStorage.setItem("jwt", token);
  } else {
    localStorage.setItem("jwt", "");
  }
}

export function fetchToken() {
  return localStorage.getItem("jwt");
}

export function removeToken() {
  return localStorage.removeItem("jwt");
}

export const setAxiosGlobal = () => {
  axios.interceptors.response.use(
    function (response) {
      if (response.headers.location) {
        return axios.get(response.headers.location);
      }

      return Promise.resolve(response);
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  if (fetchToken()) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${fetchToken()}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
  return;
};

export const setHistoryRoute = () => {
  localStorage.setItem("history", window.location.pathname);
};

export const getHistory = () => {
  const event = localStorage.getItem("history");

  if (event) {
    return {
      is_present: true,
      link: event,
    };
  } else {
    return {
      is_present: false,
      link: null,
    };
  }
};

export const removeHistory = () => {
  return localStorage.removeItem("history");
};
