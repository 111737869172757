import moment from "moment";

export function getDateDiffInMins(to, from) {
  const now = moment(to);
  const end = moment(from);

  const duration = moment.duration(now.diff(end));

  const minutes = duration.asMinutes();
  return parseInt(minutes, 10);
}
