import {
  getAllPositions,
  getDevices,
  getGroups,
  getPositionBasedByDevice,
  getReportSummary,
} from "./services";
import * as TYPES from "./constants";

export function getDevicesActions(isQuatrix) {
  return (dispatch) => {
    dispatch({
      type: TYPES.GETTING_DEVICES,
    });

    getDevices(isQuatrix)
      .then((res) => {
        dispatch({
          type: TYPES.LIST_DEVICES,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ERROR,
          payload: err,
        });
      });
  };
}

export function getGroupsActions(isQuatrix) {
  return (dispatch) => {
    dispatch({
      type: TYPES.GETTING_GROUPS,
    });

    getGroups(isQuatrix)
      .then((res) => {
        dispatch({
          type: TYPES.LIST_GROUPS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ERROR,
          payload: err,
        });
      });
  };
}

export function getPositionsByDeviceAction(isQuatrix, deviceId, from, to) {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_POSITIONS,
    });
    getPositionBasedByDevice(isQuatrix, deviceId, from, to)
      .then((res) => {
        dispatch({
          type: TYPES.LIST_POSITION,
          payload: res,
          single: true,
          recall: true,
          deviceId,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ERROR,
          payload: err,
        });
      });
  };
}

export function getAllPostionsActions(isQuatrix) {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_POSITIONS,
    });
    getAllPositions(isQuatrix)
      .then((res) => {
        dispatch({
          type: TYPES.LIST_POSITION,
          payload: res,
          single: false,
          recall: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ERROR,
          payload: err,
        });
      });
  };
}

export function getReportSummaryAction(isQuatrix, deviceId, from, to) {
  return (dispatch) => {
    dispatch({
      type: TYPES.GET_REPORTS,
    });

    getReportSummary(isQuatrix, deviceId, from, to)
      .then((res) => {
        dispatch({
          type: TYPES.REPORT_SUMMARY,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: TYPES.ERROR,
          payload: err,
        });
      });
  };
}
