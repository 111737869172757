import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const AddCarrierReducer = (
  state: any = {
    sideLoading: false,
    adding: false,
    detail: "",
    message: "",
    carrier: {},
  },
  action: {
    type: string,
    payload?: any,
  }
) => {
  switch (action.type) {
    case TYPES.ADDING_CARRIER:
      return {
        ...state,
        adding: true,
      };
    case TYPES.CARRIER_SUCCESS:
      return {
        ...state,
        adding: false,
        carrier: action.payload,
        message: "Added successfuly",
      };
    case TYPES.CARRIER_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        adding: false,
        detail: action.payload?.detail,
      };
    case TYPES.SIDE_LOAD:
      return {
        ...state,
        sideLoading: true,
      };
    case TYPES.GET_COUNTRIES:
      return {
        ...state,
        sideLoading: false,
        countries: action.payload.countries,
      };
    case TYPES.SIDE_LOAD_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        sideLoading: false,
        detail: action.payload?.detail,
      };
    default:
      return state;
  }
};

export default AddCarrierReducer;
