import { styled } from "baseui";

export const OrderPopupContainer = styled("section", () => {
  return {
    background: "#fff",
    minWidth: "270px",
    width: "auto",
    height: "auto",
    position: "absolute",
    zIndex: "200",
    bottom: "2%",
    left: "2%",
  };
});
