import * as config from "../config";

const PONTY_ASSET_MAPPING = {
  logo: "https://res.cloudinary.com/quatrix/image/upload/v1633449915/assets/web/ponty/logo-new.png",
  label: "Ponty Pridd Holdings - Operations",
  small_text: "Ponty",
  traccarURL: config.pontyTraccarUrl,
  mobileTraccarURL: config.pontyMobileTraccarUrl,
  email: "info@pontypriddholdings.com",
  websocketMobileTraccarURL: `wss${config?.pontyMobileTraccarUrl?.substring(
    5
  )}`,
  websocketTraccarURL: `wss${config?.pontyTraccarUrl?.substring(5)}`,
  primaryColor: "",
  favicon:
    "https://res.cloudinary.com/quatrix/image/upload/v1634543578/assets/web/ponty/favicon.ico",
  manifest: "/ponty-manifest.json",
};

export default PONTY_ASSET_MAPPING;
