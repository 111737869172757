import {
  createPrices,
  createProducts,
  updatePrices,
  updateProducts,
  fetchPrices,
  deletePrices,
  fetchProducts,
  fetchVehicleVariants,
  deleteProduct,
  fetchGlobalVariants,
  fetchUom,
} from "./services";
import * as TYPES from "./constants";

export const removePrices = (price_id: number) => async (
  dispatch: Function
) => {
  dispatch({
    type: TYPES.SAVING,
  });

  deletePrices(price_id)
    .then((res) => {
      dispatch({
        type: TYPES.DELETE_PRICES,
        payload: res,
        price_id,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const getUoms = () => async (dispatch) => {
  fetchUom().then((res) => {
    dispatch({
      type: TYPES.GET_UOMS,
      payload: res,
    });
  });
};

export const getPrices = (price_id?: number | null) => async (
  dispatch: Function
) => {
  if (price_id) {
    dispatch({
      type: TYPES.SIDE_LOADING,
    });
  } else {
    dispatch({
      type: TYPES.LOADING,
    });
  }
  fetchPrices(price_id)
    .then((res) => {
      if (price_id) {
        dispatch({
          type: TYPES.FETCH_PRICE,
          payload: res,
        });
      } else {
        dispatch({
          type: TYPES.FETCH_PRICES,
          payload: res,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const addProducts = (payload: any) => async (dispatch: Function) => {
  dispatch({
    type: TYPES.SAVING,
  });

  createProducts(payload)
    .then((res) => {
      dispatch({
        type: TYPES.ADD_PRODUCTS,
        payload: res,
      });
    })
    .catch((Err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: Err.response.data,
      });
    });
};

export const editProducts = (payload: any, product_id: number) => async (
  dispatch: Function
) => {
  dispatch({
    type: TYPES.SAVING,
  });

  updateProducts(
    {
      ...payload,
      image:
        "https://res.cloudinary.com/quatrix/image/upload/v1611212619/assets/web/vehicles/v1/truck.svg",
    },
    product_id
  )
    .then((res) => {
      dispatch({
        type: TYPES.EDIT_PRODUCTS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const editPrices = (payload: any, price_id: number) => async (
  dispatch: Function
) => {
  dispatch({
    type: TYPES.SAVING,
  });

  updatePrices(payload, price_id)
    .then((res) => {
      dispatch({
        type: TYPES.UPDATE_PRICES,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const addPrices = (payload: any) => async (dispatch: Function) => {
  dispatch({
    type: TYPES.SAVING,
  });

  createPrices(payload)
    .then((res) => {
      dispatch({
        type: TYPES.ADD_PRICE,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const getProducts = () => async (dispatch: Function) => {
  dispatch({
    type: TYPES.LOADING,
  });

  fetchProducts()
    .then((res) => {
      dispatch({
        type: TYPES.FETCH_PRODUCTS,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const getOneProduct = (product_id: number) => async (
  dispatch: Function
) => {
  dispatch({
    type: TYPES.SIDE_LOADING,
  });

  fetchProducts(product_id)
    .then((res) => {
      dispatch({
        type: TYPES.FETCH_SINGLE_PRODUCT,
        payload: res,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const getVehicleVariant = (variant) => async (dispatch: Function) => {
  dispatch({
    type: TYPES.LOADING,
  });

  fetchVehicleVariants(variant)
    .then((res) => {
      dispatch({
        type: "FETCH_VARIANT",
        payload: res,
        variant,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const omitProduct = (product_id) => async (dispatch: Function) => {
  dispatch({
    type: TYPES.SAVING,
  });

  deleteProduct(product_id)
    .then((Res) => {
      dispatch({
        type: TYPES.DELETE_PRODUCTS,
        payload: Res,
        product_id,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};

export const getGlobalVariants = (variant_id: string) => async (
  dispatch: Function
) => {
  dispatch({
    type: TYPES.LOADING,
  });

  fetchGlobalVariants(variant_id)
    .then((res) => {
      dispatch({
        type: TYPES.FETCH_GLOBAL_VARIANTS,
        payload: res,
        variant: variant_id,
      });
    })
    .catch((err) => {
      dispatch({
        type: TYPES.ERROR,
        payload: err.response.data,
      });
    });
};
