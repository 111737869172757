import { styled } from "baseui";
import { getDateDiffInMins } from "../helpers/date";

export const DetailsContainer = styled("section", () => {
  return {
    maxWidth: "1280px",
    margin: "0px auto 0px auto",
    width: "100%",
  };
});

export const BackButtonHolder = styled("section", () => {
  return {
    position: "relative",
    top: 0,
    left: 0,
  };
});

export const OrderDetails = styled("section", () => {
  return {
    maxWidth: "720px",
    margin: "0px auto 0px auto",
    width: "100%",
  };
});

export const OrderLayout = styled("section", () => {
  return {
    display: "grid",
    gridTemplateColumns: "190px 1fr",
    gridTemplateRows: "1fr",
    gridColumnGap: "8px",
    "@media only screen and (max-width: 863px)": {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "5px 1fr",
      gridColumnGap: "8px",
      gridRowGap: "8px",
    },
  };
});

export const SideMenuHolder = styled("aside", () => {});

export const OrderTableHolder = styled("main", () => {
  return {
    padding: "8px",
    "@media only screen and (max-width: 863px)": {
      marginTop: "8px",
    },
  };
});

export const SideMenuItem = styled("li", () => {
  return {
    marginBottom: "4px",
    "@media only screen and (max-width: 863px)": {
      marginRight: "4px",
      marginBottom: "0px",
      width: "144px",
      fontsize: "12px",
    },
  };
});

export const SideMenuItems = styled("ul", () => {
  return {
    listStyleType: "none",
    padding: "0px",
    "@media only screen and (max-width: 863px)": {
      display: "flex",
      margin: "0px 0px 0px 0px",
      padding: "4px",
      overflowX: "scroll",
      width: "100%",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  };
});

export const BottomMenuItems = styled("div", () => {
  return {
    // listStyleType: "none",
    // padding: "0px",
  };
});

export const SideMenuItemContainer = styled("nav", () => {
  return {
    position: "fixed",
    width: "190px",
    height: "1fr",
    background: "#fff",
    "@media only screen and (max-width: 863px)": {
      // display: "flex",
      width: "100%",
      background: "#fff",
      position: "fixed",
      marginTop: "-24px",
    },
  };
});

export const TableHeader = styled("section", ({ itemNo }) => {
  return {
    display: "grid",
    gridTemplateColumns: `repeat(${itemNo}, 1fr)`,
    gridTemplateRows: "24px",
    gridColumnGap: "8px",
    "@media only screen and (max-width: 863px)": {
      display: "none",
    },
  };
});

export const TableCell = styled("section", () => {
  return {
    display: "flex",
    placeItems: "center",
    color: "#a1a1a1",
  };
});

export const TableRowItemHolder = styled("section", ({ itemNo, item }) => {
  return {
    display: "grid",
    gridTemplateColumns: `repeat(${itemNo}, 1fr)`,
    gridTemplateRows: "auto",
    gridColumnGap: "0.5rem",
    borderRadius: "4px",
    margin: "8px 0px",
    padding: "8px 16px",
    height: "auto",
    cursor: "pointer",
    border:
      getDateDiffInMins(new Date(), new Date(item.created_at)) > 5 &&
      item.order_status_id !== "delivered" &&
      item.order_status_id !== "cancelled"
        ? `1px solid #ff0000`
        : "1px solid rgba(196, 196, 196, 0.5)",
    boxSizing: "border-box",
    "@media only screen and (max-width: 863px)": {
      display: "none",
    },
  };
});

export const TableRowItem = styled("section", () => {
  return {
    display: "grid",
    gridTemplateRows: `repeat(3, auto)`,
    gridTemplateColumns: "1fr",
    gridRowGap: "8px",
    "@media only screen and (max-width: 863px)": {
      display: "none",
    },
  };
});

export const MobileItemContainer = styled("section", ({ item }) => {
  return {
    display: "none",
    "@media only screen and (max-width: 863px)": {
      display: "grid",
      gridTemplateColumns: `24px 1fr 24px`,
      gridTemplateRows: "1fr",
      gridRowGap: "4px",
      border:
        getDateDiffInMins(new Date(), new Date(item.created_at)) > 5 &&
        item.order_status_id !== "delivered" &&
        item.order_status_id !== "cancelled"
          ? `1px solid #ff0000`
          : "1px solid rgba(196, 196, 196, 0.5)",
      width: "100%",
      padding: "8px",
      margin: "8px 0px",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
});

export const MobileItem = styled("section", () => {
  return {
    display: "none",

    "@media only screen and (max-width: 863px)": {
      display: "grid",
      gridTemplateRows: `repeat(2, auto)`,
      gridTemplateColumns: "1fr",
      gridRowGap: "4px",
    },
  };
});
