// @flow

import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const ShipperReducer = (
  state = {
    isLoading: false,
    isSideFetching: false,
    isUpdating: false,
    shippers: [],
    detail: "",
    message: "",
    shipper: {},
    isCreating: false,
    isDeleting: false,
  },
  action
) => {
  switch (action.type) {
    case TYPES.FETCHING:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.DELETING:
      return {
        ...state,
        isDeleting: true,
      };
    case TYPES.CREATING:
      return {
        ...state,
        isCreating: true,
      };
    case TYPES.UPDATING:
      return {
        ...state,
        isUpdating: true,
      };
    case TYPES.DELETE_SHIPPER:
      return {
        ...state,
        isDeleting: false,
      };
    case TYPES.UPDATE_SHIPPER:
      return {
        ...state,
        isUpdating: false,
        shipper: action.payload,
      };
    case TYPES.CREATE_SHIPPER:
      return {
        ...state,
        isCreating: false,
        shippers: [action.payload, ...state.shippers],
      };
    case TYPES.FETCH_SHIPPERS:
      return {
        ...state,
        isLoading: false,
        shippers: action.payload.shippers,
        // sort: action.sort,
        // dir: action.dir,
        // per_page: action.payload.shippers?.per_page,
        // total: Math.ceil(
        //   action.payload.shippers.total / action.payload.shippers.paginate
        // ),
        // page: action.payload.carriers.page,
      };
    case TYPES.FETCH_SINGLE_SHIPPER:
      return {
        ...state,
        isLoading: false,
        shipper: action.payload,
      };
    case TYPES.ERRORS:
      showErrors(action.payload?.errors, action.payload.detail);
      return {
        ...state,
        isLoading: false,
        detail: action.payload.detail,
        isCreating: false,
        isUpdating: false,
      };
    default:
      return state;
  }
};

export default ShipperReducer;
