import * as TYPES from "./constants";
import { showErrors } from "../helpers/errors";

const ShipperRoutePlacesReducer = (
  state = {
    isLoading: false,
    records: [],
    record: {},
    close: false,
    bulkSaving: false,
    sync: false,
  },
  action
) => {
  switch (action.type) {
    case TYPES.LOADING:
      return {
        ...state,
        isLoading: true,
        close: false,
      };
    case TYPES.SYNC:
      return {
        ...state,
        isLoading: false,
        sync: true,
      };
    case TYPES.ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        detail: action.payload?.detail,
        close: false,
        sync: false,
      };
    case TYPES.GET_SINGLE_ROUTE_PLACES:
      return {
        ...state,
        record: action.payload,
        isLoading: false,
      };
    case TYPES.GET_ROUTE_PLACES:
      return {
        ...state,
        records: action.payload.routePlaces,
        isLoading: false,
      };
    case TYPES.CREATE_ROUTE_PLACES:
      return {
        ...state,
        record: action.payload,
        close: true,
        records: [...state.records, action.payload],
      };
    case TYPES.EDIT_ROUTE_PLACES:
      return {
        ...state,
        record: action.payload,
        records: state.records.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        close: true,
        sync: false,
      };
    case TYPES.DELETE_ROUTE_PLACES:
      return {
        ...state,
        records: state.records.filter(
          (item) => item.id !== action.payload.unique_id
        ),
        isLoading: false,
      };
    case TYPES.BULK_SAVING:
      return {
        ...state,
        bulkSaving: true,
        close: false,
      };
    case TYPES.BULK_COMPLETE:
      return {
        ...state,
        bulkSaving: false,
        close: true,
      };
    default:
      return state;
  }
};

export default ShipperRoutePlacesReducer;
