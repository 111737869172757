import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const ProductReducer = (
  state: any = {
    isLoading: true,
    products: [],
    prices: [],
    saving: false,
    detail: [],
    price: {},
    product: {},
    sideLoading: false,
    currencies: [],
    countries: [],
    uoms: [],
  },
  action: {
    type: string,
    payload?: any,
  }
) => {
  switch (action.type) {
    case TYPES.SAVING:
      return {
        ...state,
        saving: true,
        isLoading: false,
        close: false,
      };
    case TYPES.GET_UOMS:
      return {
        ...state,
        uoms: action.payload.uoms,
      };
    case TYPES.LOADING:
      return {
        ...state,
        saving: false,
        isLoading: true,
        close: false,
      };
    case TYPES.DELETE_PRODUCTS:
      return {
        ...state,
        close: true,
        saving: false,
        products: state.products.filter(
          (item) => item.id !== action.product_id
        ),
      };
    case TYPES.DELETE_PRICES:
      return {
        ...state,
        prices: state.prices.filter((item) => item.id !== action.price_id),
        saving: false,
        close: true,
        price: {},
      };
    case TYPES.ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        detail: action.payload?.detail,
        saving: false,
        isLoading: false,
      };
    case TYPES.FETCH_PRICE:
      return {
        ...state,
        price: action.payload,
        saving: false,
        isLoading: false,
        sideLoading: false,
      };
    case TYPES.FETCH_PRICES:
      return {
        ...state,
        prices: action.payload.variants,
        saving: false,
        isLoading: false,
      };
    case TYPES.ADD_PRODUCTS:
      return {
        ...state,
        products: [...state.products, action.payload],
        saving: false,
        isLoading: false,
        close: true,
      };
    case TYPES.EDIT_PRODUCTS:
      return {
        ...state,
        product: action.payload,
        saving: false,
        isLoading: false,
        products: state.products.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        close: true,
      };
    case TYPES.UPDATE_PRICES:
      return {
        ...state,
        price: action.payload,
        isLoading: false,
        saving: false,
        prices: state.prices.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        close: true,
      };
    case TYPES.ADD_PRICE:
      return {
        ...state,
        isLoading: false,
        saving: false,
        price: action.payload,
        prices: [...state.prices, action.payload],
        close: true,
      };
    case TYPES.FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        isLoading: false,
      };
    case TYPES.FETCH_SINGLE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        isLoading: false,
        sideLoading: false,
      };
    case "FETCH_VARIANT":
      return {
        ...state,
        [action.variant]: action.payload[action.variant],
        isLoading: false,
      };
    case TYPES.FETCH_GLOBAL_VARIANTS:
      return {
        ...state,
        [action.variant]: action.payload[action.variant],
        isLoading: false,
      };
    default:
      return state;
  }
};

export default ProductReducer;
