import { styled } from "baseui";

export const TableContainer = styled("div", {
  overflowX: "auto",
  paddingTop: "8px",
});

export const Table = styled("table", {
  borderCollapse: "collapse",
  borderSpacing: 0,
  width: "100%",
});

export const Th = styled("th", {
  textAlign: "left",
  padding: "8px",
});

export const Td = styled("td", {
  textAlign: "left",
  padding: "8px",
});

export const NameContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  minWidth: "300px"
});
