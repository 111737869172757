// @flow

import { showErrors } from "../helpers/errors";
import * as TYPES from "./constants";

const SettingReducer = (
  state: any = {
    settingsLoading: false,
    savingSettings: false,
    detail: "",
    message: "",
    success: false,
    current_variant: {},
  },
  action: {
    type: string,
    payload?: any,
  }
) => {
  switch (action.type) {
    case TYPES.FETCH_VEHICLE_VARIANTS:
      return {
        ...state,
        [action.variant]: action.payload[action.variant],
        close: false,
        settingsLoading: false,
      };
    case TYPES.LOADING_SETTINGS:
      return {
        ...state,
        settingsLoading: true,
        close: false,
      };
    case TYPES.SETTINGS_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        message: action.payload.message,
        detail: action.payload?.detail,
        success: false,
      };
    case TYPES.CREATE_VARIANT:
      return {
        ...state,
        savingSettings: false,
        [action.variant]: [action.payload, ...state[action.variant]],
        close: true,
      };
    case TYPES.UPDATE_VARIANT:
      return {
        ...state,
        savingSettings: false,
        [action.variant]: [
          ...state[action.variant].map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        ],
        close: true,
      };
    case TYPES.DELETE_VARIANT:
      return {
        ...state,
        savingSettings: false,
        [action.variant]: state[action.variant].filter(
          (item) => item.id !== action.variant_id
        ),
        close: false,
      };
    case TYPES.GET_VARIANT:
      return {
        ...state,
        settingsLoading: false,
        current_variant: action.payload,
      };
    default:
      return state;
  }
};

export default SettingReducer;
